<script setup lang="ts">
const { isMobile } = useDevice()
</script>

<template>
  <div id="kg-discovery" class="kg-discovery">
    <div class="z-10 mt-3 lg:mt-6">
      <nuxt-img
        src="/images/logo-kognisi-desktop.webp"
        width="92"
        alt="logo-kognisi-small"
        loading="lazy"
        size="sm:54px md:92px"
        format="webp"
        quality="70"
        preload
      />
      <div class="kg-discovery__title">
        Jawab beberapa pertanyaan untuk lebih mengetahui diri kamu.
      </div>
      <a-button type="primary" shape="round">
        <a href="https://discovery.kognisi.id/" target="_blank">
          Temukan Potensimu
        </a>
      </a-button>
    </div>
    <div class="kg-discovery__ilustrasi">
      <nuxt-img
        :src="
          isMobile
            ? '/images/course/ilustrasi-discovery-mobile.webp'
            : '/images/course/ilustrasi-discovery-desktop.webp'
        "
        alt="ilustrasi-discovery"
        loading="lazy"
        size="sm:100%"
        format="webp"
        quality="70"
      />
    </div>
    <div class="z-10 mt-3 lg:mt-6">
      <nuxt-img
        :src="
          isMobile
            ? 'images/course/logo-growthcenter-mobile.webp'
            : 'images/course/logo-growthcenter-desktop.webp'
        "
        alt="logo-growthcenter"
        loading="lazy"
        size="sm:100%"
      />
    </div>
  </div>
</template>

<style lang="scss">
@import './styles.scss';
</style>
